import React from "react";
import { BsArrowLeft } from "react-icons/bs";

import eaas from "assets/images/svg/eaas.svg";
import { Link } from "react-router-dom";
import Products from "components/ExplorerAsAService/Products";
import ContactUsForm from "components/contactUs/ContactUsForm";

export function Application(params) {
  let Card = (
    <div className={`card card-solution h-100 p-0`}>
      <div
        className="d-grid"
        style={{
          padding: "0.75rem 1.5rem 1.875rem 1.5rem",
        }}
      >
        <div className="icon-container">
          {params.img && (
            <img
              src={require(`assets/images/icon/${params.img}`).default}
              alt=""
              className="icon-img"
            />
          )}
        </div>

        <h5 className="fs-5" style={{ fontWeight: 500 }}>
          {params.name}
        </h5>
        <p className="fs-6 fw-normal text-black pt-1">{params.children}</p>
      </div>
    </div>
  );

  return (
    <div className={`${params.className}`}>
      {params.cs ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(e) => {
            e.preventDefault();
            alert("Coming soon!");
          }}
          href=""
        >
          {Card}
        </a>
      ) : (
        Card
      )}
    </div>
  );
}

const ExplorerAsAService = () => {
  return (
    <div className="position-relative mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6">
            <div style={{ marginBottom: "56px" }}>
              <Link
                className="link-arrow text-secondary d-inline-flex align-items-center"
                to="/solutions"
              >
                <BsArrowLeft className="arrow-right me-2" /> Back to Solutions
              </Link>
            </div>
            <div className="d-flex flex-column mt-3 text-center text-sm-start">
              <div>
                <h1 className="heading-g2">Explorer-as-a-Service (EaaS)</h1>

                <p
                  className="text-secondary fw-normal"
                  style={{ fontSize: "1.125rem" }}
                >
                  Build your own Superior Blockchain Explorer in
                  <strong> 30 days</strong>
                </p>
              </div>
              <div className="gap-7" />
              <div>
                <a href="#contact-us">
                  <button
                    className="btn btn-primary me-3 mb-3 mb-lg-0 btn-lg fs-6"
                    style={{
                      paddingInlineStart: "5.25rem",
                      paddingInlineEnd: "5.25rem",
                    }}
                  >
                    Request a demo
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-6 d-none d-sm-block">
            <img src={eaas} alt="Explorer as a service" />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center flex-column explorer-bg-gradient">
        <div className="container">
          <div className="gap-4"></div>
          <h2 className="heading-4">Our Solutions and technologies</h2>
          <p style={{ fontSize: "1.125rem" }}>
            Grindy Explorer has built on top of exclusive solutions and
            technologies to <strong>BREAK</strong> the limitations of the
            current Block Explorer that dramatically suffering blockchain
            adoption.
          </p>
          <div className="gap-3"></div>
          <div className="row justify-content-center">
            <Application
              img="graphql.svg"
              name="GraphQL"
              className="col-md-4 mb-3"
            >
              Provide developers with GraphQL APIs that are speedy, versatile,
              and user-friendly.
            </Application>
            <Application
              img="p2p.svg"
              name="P2P syncing"
              className="col-md-4 mb-3"
            >
              P2P synchronization solution with enhanced dependability, data
              processing, and dedicated service.
            </Application>
            <Application
              img="infrastructure.svg"
              name="Dynamic Infrastructure"
              className="col-md-4 mb-3"
            >
              Dynamic infrastructure endures the massive number of users.
            </Application>
          </div>
          <div className="row justify-content-center">
            <Application
              img="checked.svg"
              name="Worker Checking"
              className="col-md-3 mb-3"
            >
              Top-notch in data accuracy.
            </Application>
            <Application
              img="wrech.svg"
              name="Maintainability"
              className="col-md-3 mb-3"
            >
              Adaptable architecture and code cleanliness to maximize
              maintainability.
            </Application>
            <Application
              img="data-catching.svg"
              name="Exclusive Data Caching"
              className="col-md-3 mb-3"
            >
              Advanced Live-APIs and resource-saving.
            </Application>
            <Application
              img="data.svg"
              name="Unparalleled Data-Parse"
              className="col-md-3 mb-3"
            >
              Architecture of a data parser that generates graphs and
              statistics.
            </Application>
          </div>
        </div>
        <div className="gap-5" />
      </div>

      <div
        className="container"
        id="our-products"
        style={{
          marginBottom: "6.25rem",
        }}
      >
        <div className="gap-5" />
        <h2
          className="heading-4"
          style={{
            fontSize: "1.75rem",
          }}
        >
          Our products
        </h2>
        <p
          className="text-body-2"
          style={{ fontSize: "1.125rem", whiteSpace: "pre-line" }}
        >
          {`With deep knowledge in the blockchain space, from layer One to
              DAPPs and applications. We devote our expertise to building the
              web3 product to fulfill the Decentralize Web Future.`}
        </p>
        <div className="gap-3"></div>
        <Products />
      </div>

      <div className="divider-gradient" />

      <div className="container" id="contact-us">
        <div className="gap-5"></div>

        <div
          className="row position-relative align-items-center flex-column"
          style={{
            marginBottom: "8rem",
            zIndex: 1,
          }}
        >
          <div
            className="d-flex justify-content-center flex-wrap"
            style={{
              gap: "5.5rem",
              marginBottom: "5.8125rem",
            }}
          >
            <img
              alt="deliverey img"
              src={require("assets/images/diploma/deliverey.svg").default}
            />
            <img
              alt="maintenance img"
              src={require("assets/images/diploma/maintenance.svg").default}
            />
            <img
              alt="risk-free img"
              src={require("assets/images/diploma/risk-free.svg").default}
            />
            <img
              alt="guarantea img"
              src={require("assets/images/diploma/guarantea.svg").default}
            />
          </div>

          <div className="col-12 col-md-8 col-lg-6">
            <div className="col-lg-8 text-center w-100">
              <h1 className="heading-g2">Contact Us</h1>

              <p style={{ fontWeight: 500, fontSize: "1.125rem" }}>
                Interested in Grindy Explorer values? Get in touch to explore
                more and get hands-on with the solution that advances your
                blockchain ecosystem.
              </p>
            </div>
          </div>

          <div
            className="col-12 col-md-8 col-lg-6"
            style={{ marginTop: "2.8125rem" }}
          >
            <div className={`card card-solution h-100 py-4 px-3 `}>
              <div className="card-body">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </div>

        <img
          src={require("assets/images/svg/contact-us.svg").default}
          className="contact-us-bg pe-none translate-middle-x position-absolute start-50"
          alt="Contact us"
        />
      </div>
    </div>
  );
};

export default ExplorerAsAService;
