import React from "react";

import productsBg from "assets/images/products/products-bg.svg";
import { BsArrowRight } from "react-icons/bs";
import DefaultLayout from "components/layouts/DefaultLayout";

import "./Products.scss";
import CantFindSolution from "components/Solutions/CantFindSolution";

const ProductSection = (props) => {
  return (
    <div
      className={`row ${props.imgLeft ? "flex-row-reverse" : null} ${
        props.className
      }`}
    >
      <div className="col-12 col-lg-6">
        <h1 className="heading-4 mb-3 text-black">{props.title}</h1>
        <p
          style={{
            fontWeight: 500,
            fontSize: "1.125rem",
            marginBottom: "1rem",
          }}
        >
          {props.subtitle}
        </p>
        <div className="text-secondary fs-6 fw-normal">{props.children}</div>

        <a
          className="link-arrow"
          href={props.link}
          target="_blank"
          rel="noreferrer"
          style={{
            fontWeight: 600,
          }}
        >
          Explore now
          <BsArrowRight
            style={{ marginLeft: "0.3125rem" }}
            className="arrow-left"
          />
        </a>
      </div>

      <div className="col-12 col-lg-6 pt-5 pt-lg-0">
        <img
          src={props.img}
          alt={props.title}
          className={`h-100 w-100 ${
            props.imgLeft ? "float-start" : "float-lg-end"
          }`}
        />
      </div>
    </div>
  );
};

const Products = () => {
  return (
    <>
      <DefaultLayout
        title="What We Do"
        bg={productsBg}
        des={`The products we build are changing the way industry
      leaders do their core business`}
      >
        <div className="products-list-project">
          <ProductSection
            title="CasperStats"
            subtitle="Casper Blockchain Explorer"
            link="https://casperstats.io/"
            img={require("assets/images/products/casper-lg.png").default}
            className="product-section pt-0"
            imgLeft
          >
            <p>
              The Blockchain Explorer and Analytics Platform for Casper Network,
              Casper Network is a decentralized layer one Proof-of-Stake
              blockchain built off the Casper CBC specification. Casper is
              designed to accelerate enterprise and developer adoption of
              blockchain technology today and evolve to meet the needs of the
              future.
            </p>
            <a href="https://casperstats.io" target="_blank" rel="noreferrer">
              CasperStats.io
            </a>{" "}
            project supported by{" "}
            <a href="https://devxdao.com" target="_blank" rel="noreferrer">
              DEVxDAO.com
            </a>
          </ProductSection>

          <ProductSection
            title="Heroes & Empires"
            subtitle="The next-gen RPG game"
            link="https://heroesempires.com/"
            img={require("assets/images/products/he-lg.svg").default}
            className="product-section"
          >
            Heroes & Empires is a strategy game that combines the distinctive
            elements of Idle RPG and the tactics of auto chess, connected with
            blockchain technology. Besides, by taking advantage of the
            Non-Fungible Token (NFT) characteristic to create characters and
            items and Play-2-Earn elements, players can earn a steady income
            thanks to DeFi integration while still being satisfied with an
            entertaining gaming experience.
          </ProductSection>

          <ProductSection
            title="Gafi Network"
            subtitle="The network of Game Finance"
            link="https://gafi.network/"
            img={require("assets/images/products/gafi-lg.svg").default}
            className="product-section"
            imgLeft
          >
            <p>
              The Network of Game Finance, The foundation builds with top-class
              technologies, architecture, and mechanisms for the next wave of
              Blockchain-based Gaming.
            </p>
            <a href="https://gafi.network" target="_blank" rel="noreferrer">
              Gafi Network
            </a>{" "}
            project supported by{" "}
            <a href="https://web3.foundation" target="_blank" rel="noreferrer">
              Web3.foundation
            </a>
          </ProductSection>

          <ProductSection
            title="Overmint Market"
            subtitle="New NFT Market"
            link="https://overmint.io/"
            className="product-section border-0"
            img={require("assets/images/products/overmint-lg.svg").default}
          >
            <p>
              The New NFT Market on EVM Based, Heroes & Empires has exquisitely
              digital collectibles created using blockchain technology. Each
              collectible is matchless, genuine and varies in rarity.
            </p>
          </ProductSection>
        </div>
      </DefaultLayout>

      <CantFindSolution />
    </>
  );
};

export default Products;
