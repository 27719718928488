export default function DefaultLayout(props) {
  return (
    <main className={`main ${props.className}`}>
      <div
        className="header-background"
        style={{ backgroundImage: `url(${props.bg})` }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{
              padding: "8rem 0 12.75rem 0",
            }}
          >
            <div className="col-lg-8 text-center">
              <h1 className="heading-g2">{props.title}</h1>

              {props.des && (
                <p
                  className="text-secondary text-body-2 space"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {props.des}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">{props.children}</div>
    </main>
  );
}
