import React from "react";

import cryptoviet from "assets/images/logo/cryptoviet.svg";

import Spline from "@splinetool/react-spline";

import "./Home.scss";
import Web3 from "./Web3";
import Layer from "./Layer";
import Source from "./Source";
import { Link } from "react-router-dom";
import ButtonLink from "components/ButtonLink/ButtonLink";

export default function Home() {
  return (
    <main className="main">
      <div className="bg-hero text-white position-relative overflow-hidden">
        <div className="container-lg">
          <div
            className="home-blockchain-solution position-relative"
            style={{ zIndex: 1 }}
          >
            <div
              style={{ zIndex: 2 }}
              className="col-lg-6 text-center text-lg-start"
            >
              <h1 className="heading-g2" style={{ fontSize: "3.25rem" }}>
                Blockchain Solutions for the Decentralized Web
              </h1>
              <p
                className="heading-4 fw-normal"
                style={{
                  marginBottom: "5.125rem",
                }}
              >
                Never stop building
              </p>
              <Link to="/solutions/explorer">
                <ButtonLink context="Let’s build from here" />
              </Link>
            </div>
          </div>
          <div
            className="position-absolute w-100 h-100 top-0 pe-none"
            style={{ transform: "translateX(10%)" }}
          >
            <Spline
              onWheel={() => {
                console.log("hello");
              }}
              scene="https://prod.spline.design/XWG6AdostEl-qDVx/scene.splinecode"
            />
          </div>
        </div>
      </div>

      <Web3 />
      <div className="divider-gradient" />

      <Layer />

      <Source />
    </main>
  );
}
