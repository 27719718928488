import ButtonLink from "components/ButtonLink/ButtonLink";

import ContactUsForm from "components/contactUs/ContactUsForm";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function Collaborate() {
  const [isCollaborate, setCollarabote] = useState(false);

  return (
    <>
      <ButtonLink
        onClick={() => setCollarabote(true)}
        context=" Collaborate with us"
      />

      <Modal show={isCollaborate} onHide={() => setCollarabote(false)}>
        <Modal.Header closeButton>
          <Modal.Title
            className="modal-title heading-4"
            style={{
              fontSize: "1.125rem",
            }}
          >
            Colaborate with us
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ContactUsForm type="modal" />
        </Modal.Body>
      </Modal>
    </>
  );
}
