import React from "react";
import DefaultLayout from "./layouts/DefaultLayout";
function CardImg(params) {
  return (
    <div className="col-md-6 col-lg-4 py-3">
      <div className={`card h-100 ${params.bg}`}>
        <div className="card-body py-5  d-flex align-items-center justify-content-center">
          <img
            className={`w-100 ${params.imgClass}`}
            src={
              require(`../assets/images/branding/${params.name}.svg`).default
            }
            alt=""
          />
        </div>
        <div className="card-footer text-end">
          <a
            href={
              require(`../assets/images/branding/${params.name}.svg`).default
            }
            download
          >
            .svg
          </a>
          <a
            href={
              require(`../assets/images/branding/${params.name}.png`).default
            }
            download
          >
            .png
          </a>
        </div>
      </div>
    </div>
  );
}

function Color(params) {
  return (
    <div className="col-md-6 col-lg-4 py-3">
      <div className="card h-100" style={{ backgroundColor: params.color }}>
        <div className="card-body py-5  d-flex align-items-center justify-content-center">
          <h3 className={params.textClass}>{params.color}</h3>
        </div>
      </div>
    </div>
  );
}

export default function Branding() {
  return (
    <DefaultLayout title="Branding" des="This branding guildeline">
      <div className="row">
        <div className="col-12">
          <p>
            <h2>Logos</h2>
          </p>
        </div>

        <CardImg name="logo" bg="" />
        <CardImg name="logo-tagline" bg="" />
        <CardImg name="logo-inline" bg="" />

        <CardImg name="logo-dark-bg" bg="bg-dark" />
        <CardImg name="logo-tagline-dark-bg" bg="bg-dark" />
        <CardImg name="logo-inline-dark-bg" bg="bg-dark" />

        <CardImg name="logo-white" bg="bg-dark" />
        <CardImg name="logo-tagline-white" bg="bg-dark" />
        <CardImg name="logo-inline-white" bg="bg-dark" />
      </div>
      <div className="gap-3"></div>

      <div className="row justify-content-center mt-3">
        <div className="col-12">
          <p>
            <h2>Token</h2>
          </p>
        </div>

        <CardImg name="token" bg="" imgClass="brand-token-icon" />
        <CardImg name="token-white" bg="bg-dark" imgClass="brand-token-icon" />
      </div>
      <div className="gap-3"></div>

      <div className="row mt-3">
        <div className="col-12">
          <p>
            <h2>Fonts</h2>
          </p>
        </div>
        <div className="col-12">
          <p>
            <h4>
              Heading: Red Hat Display (Extrabold 800) -{" "}
              <a
                target="_blank"
                href="https://fonts.google.com/specimen/Red+Hat+Display"
                rel="noreferrer"
              >
                Google Fonts{" "}
              </a>
            </h4>
          </p>
          <p>Paragraph: Helvetica</p>
          <p className="monospace">
            Monospace font: Fira Code{" "}
            <a
              target="_blank"
              href="https://fonts.google.com/specimen/Fira+Code"
              rel="noreferrer"
            >
              Google Fonts{" "}
            </a>
          </p>
        </div>
      </div>
      <div className="gap-3"></div>

      <div className="row mt-3">
        <div className="col-12">
          <p>
            <h2>Colors</h2>
          </p>
        </div>

        <Color color="#0e336b" textClass="text-white" />
        <Color color="#134692" textClass="text-white" />
        <Color color="#2a7ad7" textClass="text-white" />
        <Color color="#75b7ff" textClass="text-white" />
        <Color color="#99cfff" textClass="text-brand" />
        <Color color="#d2ecff" textClass="text-brand" />
        <Color color="#ffffff" textClass="text-brand" />
        <Color color="#222222" textClass="text-white" />
      </div>
    </DefaultLayout>
  );
}
