import React, { useState } from "react";
import GiftBox from "assets/images/workshop/giftbox.png";
import { Image } from "react-bootstrap";
import WorkEvent from "assets/images/workshop/banner-event.png";
import "./WorkShop.scss";
import {
  EMAIL_REGEX,
  WORKSHOP_CHESS,
  WORKSHOP_POS,
} from "components/constants";

export default function WorkShop() {
  const [email, setEmail] = useState("");

  const [logged, setLogged] = useState(false);

  const [isError, setIsError] = useState(false);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center position-relative container-lg"
      style={{
        backgroundImage: `url(${WorkEvent})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      <div className="workshop-student">
        <h1 className="display-5 fw-semibold d-flex lh-1 text-center justify-content-center">
          STUDENT&nbsp;
          <p
            style={{
              color: "#2A7AD7",
            }}
          >
            TECH HORIZON
          </p>
          &nbsp;EVENT
        </h1>

        <div className="workshop-giftbox">
          <Image src={GiftBox} alt="gift-box" />
          <p
            className="fw-medium mb-0"
            style={{
              fontSize: "1.125rem",
            }}
          >
            Trải nghiệm và đánh giá để có cơ hội nhận những phần quà hấp dẫn từ
            &nbsp;
            <span
              style={{
                color: "#2A7AD7",
              }}
            >
              Grindy
            </span>
            &nbsp;nhé!
          </p>
          <Image src={GiftBox} alt="gift-box" />
        </div>
      </div>

      <div className="workshop-input position-relative">
        <input
          placeholder="Nhập Email"
          type="email"
          value={email}
          onChange={({ target }) => {
            const value = target.value;

            setEmail(value);
          }}
          style={{
            pointerEvents: logged ? "none" : undefined,
            opacity: logged ? 0.3 : undefined,
            borderColor: isError ? "red" : undefined,
          }}
        />

        <button
          className="btn-primary"
          style={{
            background: logged ? "#222" : undefined,
          }}
          type="submit"
          onClick={() => {
            if (!email.match(EMAIL_REGEX)?.length) {
              return setIsError(true);
            }

            // un error
            setIsError(false);

            // login
            if (email.length && !logged) {
              return setLogged(true);
            }
            // log out
            setLogged(false);
          }}
        >
          {logged ? "Change Email" : "Log in"}
        </button>
      </div>

      <ul className="workshop-note">
        {[
          "Đăng nhập Email",
          "Lựa chọn dự án bên dưới",
          "Trải nghiệm và nhận thưởng",
        ].map((meta, index) => (
          <li className="d-flex align-items-center" key={meta}>
            <span>{index + 1}</span>

            <p className="mb-0">{meta}</p>
          </li>
        ))}
      </ul>

      <div className="workshop-banner">
        {["banner-pos", "banner-chess"].map((meta) => (
          <div
            key={meta}
            style={{
              pointerEvents: logged ? undefined : "none",
            }}
            onClick={() => {
              if (meta.includes("chess")) {
                window.open(WORKSHOP_CHESS + email, "_blank");
              }

              if (meta.includes("pos")) {
                window.open(WORKSHOP_POS + email, "_blank");
              }
            }}
          >
            <Image
              src={require(`assets/images/workshop/${meta}.png`).default}
              alt={meta}
              style={{
                opacity: logged ? undefined : 0.3,
              }}
            />
          </div>
        ))}
      </div>

      <div className="workshop-thank">
        {`Hai sản phẩm đang trong quá trình hoàn thiện. Mong nhân được sự thông
        cảm và góp ý chân thành từ mọi người. Xin cảm ơn!`}
      </div>
    </div>
  );
}
