import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function ButtonLink({
  title,
  link,
  context,
  styleButton,
  onClick,
}) {
  return (
    <>
      {title && <h5 className={`heading-4 text-black mb-4`}>{title}</h5>}

      {link ? (
        <Link to={link}>
          <button
            className={`btn btn-primary btn-arrow mt-2 px-4 ${styleButton}`}
          >
            {context} <BsArrowRight className="arrow" />
          </button>
        </Link>
      ) : (
        <button
          className={`btn btn-primary btn-arrow mt-2 px-4 ${styleButton}`}
          onClick={onClick}
        >
          {context} <BsArrowRight className="arrow" />
        </button>
      )}
    </>
  );
}
