import React from "react";

import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import DefaultLayout from "components/layouts/DefaultLayout";

import "./Solutions.scss";
import CantFindSolution from "./CantFindSolution";

export function Application(params) {
  let Card = (
    <div
      className={`card card-solution text-center h-100 py-4 px-3 ${params.className}`}
    >
      <div className="card-body">
        <div className="icon-container">
          {params.img && (
            <img
              src={require(`assets/images/icon/${params.img}`).default}
              alt=""
              className="icon-img"
            />
          )}
        </div>

        <h5
          className="text-1 fw-normal"
          style={{
            marginTop: "0.625rem",
            ...params.styleHeading,
          }}
        >
          {params.name}
        </h5>
        <div
          className={
            params.className === "card-cta"
              ? "text-white d-flex justify-content-center"
              : "text-secondary"
          }
        >
          {params.children}
        </div>
      </div>
    </div>
  );

  return (
    <div className="col-md-4 mb-3">
      {params.cs ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(e) => {
            e.preventDefault();
            alert("Coming soon!");
          }}
          href=""
        >
          {Card}
        </a>
      ) : null}
      {params.link ? <Link to={params.link}>{Card}</Link> : null}
    </div>
  );
}

export default function Solutions() {
  return (
    <>
      <DefaultLayout
        title="Find The best Decentralised Web 
        solution for your business."
        bg={require("assets/images/solutions-bg.svg").default}
        des="Build your own"
      >
        <div className="row justify-content-center solution-list">
          <Application img={"gaming.svg"} name="Gaming" cs={true}>
            Build your own gaming platform and the world of NFT.
          </Application>

          <Application img={"finance.svg"} name="Finance" cs={true}>
            Build your own bank with the best defending distributed system.
          </Application>
          <Application img={"social.svg"} name="Social" cs={true}>
            Decentralized social media networks protect user privacy and enhance
            data security.
          </Application>
          <Application img={"insurance.svg"} name="Insurance" cs={true}>
            Insurance system with the blockchain and smart contracts to
            transform your business operation.
          </Application>
          <Application img={"exchange.svg"} name="Exchange" cs={true}>
            Decentralize exchange, the peer-2-peer marketplaces without
            intermediary or custodian.
          </Application>
          <Application
            img={"explorer.svg"}
            link="solutions/explorer"
            name="Explorer"
          >
            An absolute product for Hyper Block Explorer
          </Application>
          <Application
            img={"more-case.svg"}
            name="...and more"
            cs={true}
            className="card-cta"
            styleHeading={{
              marginBottom: "1.5rem",
            }}
          >
            <div
              className="fs-6 d-inline"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid #F5F5F5",
                padding: "0.3125rem 2.4375rem",
              }}
            >
              Try Demo <BsArrowRight className="arrow" />
            </div>
          </Application>
        </div>
      </DefaultLayout>

      <CantFindSolution />
    </>
  );
}
