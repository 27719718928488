import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import { Link, NavLink } from "react-router-dom";

import { BsGithub } from "react-icons/bs";

const MenuListItem = [
  {
    link: "/",
    context: "Home",
  },
  {
    link: "/technologies",
    context: "Technologies",
  },
  {
    link: "/products",
    context: "Products",
  },
  {
    link: "/solutions",
    context: "Solutions",
  },
  {
    link: "/about",
    context: "About Us",
  },
  {
    link: "https://github.com/grindytech",
    context: <BsGithub />,
    target: "_blanks",
  },
];

function MenuList() {
  const [element, setElement] = useState(null);

  useEffect(() => {
    const hambuger = document.getElementsByClassName("toggler")[0];

    if (hambuger && !element) {
      setElement(hambuger);
    }

    const resizeWindow = (e) => {
      const breakpointLG = e.target.outerWidth >= 992;

      if (breakpointLG) {
        hambuger.checked = false;
      }
    };

    window.addEventListener("resize", resizeWindow);

    return () => window.removeEventListener("resize", resizeWindow);
  }, [element]);

  return (
    <>
      {React.Children.toArray(
        MenuListItem.map((menu) => (
          <li className="p-2 p-lg-0">
            <NavLink
              target={menu.target}
              exact={true}
              onClick={() => {
                if (element) {
                  element.checked = false;
                }
              }}
              className="nav-link"
              to={{
                pathname: menu.link,
              }}
              activeStyle={
                menu.target
                  ? undefined
                  : {
                      color: "#2A7AD7",
                    }
              }
            >
              {menu.context}
            </NavLink>
          </li>
        ))
      )}
    </>
  );
}

export default function Header() {
  return (
    <header className="bg-white sticky-top">
      <input type="checkbox" className="toggler d-lg-none"></input>

      <div className="hamburger d-lg-none">
        <div></div>
      </div>

      <div className="menu shadow">
        <div>
          <ul className="w-100">
            <MenuList />
          </ul>
        </div>
      </div>

      <div className="container-lg py-3">
        <div className="d-flex">
          <Link to="/" className="d-flex mb-2">
            <img src={logo} alt="" width="120" />
          </Link>

          <ul className="nav d-none d-lg-flex col-12 col-lg-auto ms-lg-auto mb-2 justify-content-center mb-md-0">
            <MenuList />
          </ul>
        </div>
      </div>
    </header>
  );
}
