import React from "react";

import DefaultLayout from "components/layouts/DefaultLayout";
import ButtonLink from "components/ButtonLink/ButtonLink";

import "./Technologies.scss";

function Application(params) {
  const heading = "text-1 fw-normal mt-3";

  return (
    <div className="col-md-6 mb-3">
      <a href={params.link} target="_blank" rel="noreferrer">
        <div
          className={`card h-100 ${params.className}`}
          style={{
            padding: "2rem 1.5625rem",
          }}
        >
          <div className="d-grid h-100" style={{ gridTemplateRows: "auto" }}>
            <img
              className="mb-3"
              src={require(`assets/images/icon/${params.img}`).default}
              alt="icon"
            />

            <div>
              <h5
                className={
                  params.className === "card-cta"
                    ? `${heading} text-white`
                    : heading
                }
                style={{ marginBottom: "1.0625rem" }}
              >
                {params.name}
              </h5>

              <p
                className={
                  params.className === "card-cta"
                    ? "text-white mb-0"
                    : "text-secondary mb-0"
                }
              >
                {params.children}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default function Technologies() {
  return (
    <>
      <DefaultLayout
        title="Blockchain Solution for building 
        the Decentralised Web"
        bg={require("assets/images/bg-decentralised.svg").default}
        des={`Grindy uses advanced technologies to cut the gap
        between technologies and utilization.`}
        className="bg-gradient-white"
      >
        <div className="row justify-content-center technologies-project">
          <div className="col-lg-8">
            <div className="row">
              <Application
                img={"gafi.svg"}
                name="Gafi Network"
                link="https://gafi.network"
              >
                The next-generation blockchain for gamers. The decentralised
                blockchain platform, the network for Game Finance.
              </Application>
              <Application
                img={"substrate.svg"}
                name="Substrate"
                link="https://substrate.io/"
              >
                A framwork for building blockchains and spawing decentrialzed
                inovation.
              </Application>
              <Application
                img={"dot.svg"}
                name="Polkadot"
                link="https://polkadot.network/"
              >
                The next-generation platform for connecting independent
                blockchain together.
              </Application>
              <Application
                img={"eth.svg"}
                name="Ethereum Virtual Machine"
                link="https://ethereum.org/en/developers/docs/evm/"
              >
                The world's most famous Smart-contract environment.
              </Application>
              <Application
                img={"subnet.svg"}
                name="Subnet Architecture"
                link="https://polkadot.network/parachains/"
              >
                The architecture for Subnet configuration, the next-generation
                of sovereign network.
              </Application>
              <Application
                className="card-cta"
                img={"git.svg"}
                name="Go to Github"
                link="https://github.com/grindytech"
              >
                See all of out code and technologies on our GitHub repository.
              </Application>
            </div>
          </div>
        </div>
      </DefaultLayout>

      <div className="tech-footer-bg technologies-radically">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <ButtonLink
                title={`Blockchain technologies that radically
                change our way of life in the coming decades.`}
                context="Try Demo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
