import React from "react";
import imageWeb3 from "assets/images/web3-2.svg";
import { Image } from "react-bootstrap";

export default function Web3() {
  return (
    <div className="home-from-web3">
      <div className="container-lg">
        <div className="row justify-content-between align-items-center flex-column-reverse flex-lg-row">
          <div className="col-lg-6 mt-5 mt-lg-0 text-center text-lg-start">
            <h2 className="mb-3 heading-4">From Web 1.0 to Web3</h2>

            <p className="lead text-body-2 d-inline-flex flex-column">
              {`The revolutionary of technologies grow dramatically, Web 1.0
            were demonstrated the power of the internet in the early day. In
            present-day Web 2.0 has dominated the market with few powerful
            companies having control of the share.`}

              <strong className="lead text-body-2 mt-4">
                {`We provide solutions that have the key object of Web 2.0 and
              combine the benefits of Web3 technologies that we believe Web
              3.0 is the way how people mean to interact through the
              internet.`}
              </strong>
            </p>
          </div>

          <div className="col-lg-6 col-md-6 col-8 text-center">
            <Image src={imageWeb3} alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
}
