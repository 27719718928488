import React from "react";

import openmark from "assets/images/icon/openmark.svg";
import casper from "assets/images/logo/casperstats_logo.svg";
import heroesempires from "assets/images/logo/heroesempires.svg";

import ButtonLink from "components/ButtonLink/ButtonLink";
export default function Source() {
  return (
    <div className="home-open-source">
      <div className="container-lg">
        <div className="row justify-content-center justify-content-md-between align-items-center">
          <div className="col-md-6 text-center text-md-start">
            <ButtonLink
              link="/technologies"
              title={`Open-source technologies to
              build the decentralized web`}
              context="All Technologies"
            />
          </div>

          <div
            className="d-flex flex-column w-auto mt-5 mt-md-0"
            style={{
              gap: "1.5625rem",
            }}
          >
            <a href="https://openmark.io" target="_blank" rel="noreferrer">
              <img className="w-100 h-100" src={openmark} alt="openmark logo" />
            </a>

            <a href="https://casperstats.io" target="_blank" rel="noreferrer">
              <img className="w-100 h-100" src={casper} alt="casper logo" />
            </a>

            <a
              href="https://heroesempires.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-100 h-100"
                src={heroesempires}
                alt="casper logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
