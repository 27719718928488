import React from "react";
import { Image } from "react-bootstrap";
import imageMutiplePlayer from "assets/images/layer.svg";

export default function Layer() {
  return (
    <div className="home-mutiple-layer">
      <div className="bg-gradient-white pb-5">
        <div className="container-lg">
          <div className="row justify-content-between align-items-center flex-column flex-lg-row">
            <div className="col-lg-6 col-md-6 col-8">
              <Image src={imageMutiplePlayer} alt="image" />
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0 text-center text-lg-start">
              <h2 className="mb-3 heading-4">Multiple Layer of Blockchain</h2>
              <p
                className="lead text-body-2 d-inline-flex flex-column"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                <strong>We are obsessed in:</strong>
                {`- Blockchain layer one architecture and mechanisms
            - Blockchain-based Gaming
            - DApps layer architecture and mechanism
            - On-chain governance protocol design
            - Rust in blockchain`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
