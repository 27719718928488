const SOCIAL_LINK = {
  twitter: "https://twitter.com/grindytech",
  medium: "",
  github: "https://github.com/grindytech/",
};

// MAINNET
const NETWORK = 1;

const EXPLORER = "https://etherscan.io/";
const INFURA = "https://mainet.infura.io/v3/b290a50e9362493e8882b002b9e44eb3";
const EMAIL_REGEX = /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;

export { NETWORK, EXPLORER, INFURA, SOCIAL_LINK, EMAIL_REGEX };

export const WORKSHOP_POS = "https://chat-workshop.grindy.io/workshop/signin?email="
export const WORKSHOP_CHESS = "https://chess-workshop.grindy.io/practice?bot=atomman&coach=legend&email="