import React from "react";
import "./About.scss";

// import muto from "assets/images/muto.png";
import joinus from "assets/images/join-us.png";
import DefaultLayout from "components/layouts/DefaultLayout";
import ButtonLink from "components/ButtonLink/ButtonLink";

// function Team(params) {
//   return (
//     <div className="text-center col-12 col-md-6 col-lg-3">
//       <img className="team-img" src={params.img} alt="team" />

//       <div className="gap-2"></div>
//       <h5 className="text-2">{params.name}</h5>
//       <p>{params.title}</p>
//     </div>
//   );
// }

export default function About() {
  return (
    <>
      <DefaultLayout
        title="Grindy Technologies"
        bg={require("assets/images/bg-tech.svg").default}
        des={`Grindy uses advanced technologies to cut the gap
        between technologies and utilization.`}
      >
        <div className="who-we-are who-we-are-container">
          <div className="row align-items-end align-items-xl-start">
            <div className="col-lg-6">
              <h5
                className="heading-4 text-black position-relative divider-gradient-pseudo"
                style={{
                  paddingBottom: "0.625rem",
                }}
              >
                Who we are
              </h5>

              <p className="text-body-2" style={{ marginTop: "1.875rem" }}>
                Starting with developers who have great faith in Web3
                technologies, we leverage the advanced technologies in
                blockchain space to build the future of Web3. Technologies have
                made the world change faster than ever before with builders,
                whose behind the changes never stop building.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                alt="photoImage"
                src={require("assets/images/wwa.svg").default}
              />
            </div>
          </div>
        </div>
      </DefaultLayout>

      {/* team */}
      <div
        className="bg-light"
        style={{
          padding: "3.6875rem 0 8.875rem 0",
          marginBottom: "5.5rem",
        }}
      >
        <div className="container">
          <div className="who-we-are-container">
            <p className="text-body-2 ps-0">Our mission</p>
            <h5
              className="heading-g2 ps-0 fs-2"
              style={{ whiteSpace: "pre-line" }}
            >
              {`The world, in some sense, belongs to coders. Our mission is to
              maximize open-source resources, and design Web3 architecture
              solutions to build the future of Blockchain.`}
            </h5>
          </div>
        </div>
      </div>

      {/* <div className="bg-about-gradient">
        <div className="container">
          <div className="who-we-are-container">
            <div className="row align-items-center">
              <div className="col-3 d-none d-lg-block">
                <img src={muto} className="muto-img" alt="" />
              </div>
              <div className="col-lg-8 text-center text-white py-5">
                <p
                  className="text-body-2 fw-light text-white"
                  style={{ marginBottom: "2.0625rem" }}
                >
                  {`“Sit at nisl, egestas est tellus volutpat facilisis. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Euismod egestas sit
                sit mauris.”`}
                </p>

                <h5
                  className="fw-semibold text-white"
                  style={{ fontSize: "1.625rem" }}
                >
                  Stefan Muto
                </h5>
                <p
                  style={{
                    fontSize: "1.125rem",
                    fontWeight: 500,
                  }}
                >
                  Founder & CEO - Grindy Technology
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about-our-team">
          <div className="col-12 text-center">
            <h5 className="heading-4">Our team</h5>
          </div>

          <div
            className="d-flex justify-content-center flex-wrap"
            style={{
              marginTop: "6.4375rem",
            }}
          >
            <Team
              img={require("assets/images/team/muto.png").default}
              name="Stefan Muto"
              title="Founder & CEO"
            />
            <Team
              img={require("assets/images/team/mike.png").default}
              name="Mike Luu"
              title="Tech Lead"
            />
            <Team
              img={require("assets/images/team/vuong.png").default}
              name="Mike Truong"
              title="Branding & Frontend"
            />
            <Team
              img={require("assets/images/team/alex.png").default}
              name="Alex Pham"
              title="UX/UI & Graphic"
            />
          </div>
        </div>
      </div> */}

      <div className="divider-gradient" />

      <div
        className="tech-footer-bg"
        style={{
          margin: "6.5rem 0 6rem 0",
        }}
      >
        <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
          <div
            className="d-none d-lg-block"
            style={{
              marginRight: "6rem",
            }}
          >
            <img src={joinus} alt="" />
          </div>

          <div className="text-center text-lg-start">
            <h2 className="heading-g2 fs-1">Join our team</h2>
            <ButtonLink context="Careers" />
          </div>
        </div>
      </div>
    </>
  );
}
