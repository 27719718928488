import React from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";
import { SOCIAL_LINK } from "components/constants";

const ListFooterItem = [
  {
    context: "Homepage",
    link: "/",
  },
  {
    context: "Technologies",
    link: "/technologies",
  },
  {
    context: "Solutions",
    link: "/solutions",
  },
  {
    context: "About Us",
    link: "about",
  },
  {
    context: "Media Kit",
  },
];

export default function Footer() {
  return (
    <footer className="footer mt-auto">
      <div className="bg-dark">
        <div className="container-lg">
          <div className="footer-container">
            <div className="d-flex flex-column align-items-center align-items-md-start">
              <img
                src={require("assets/images/logo-white.svg").default}
                alt="logo-grindy"
              />

              <a
                className="text-body-2 fs-6 text-white my-3"
                href="mailto:hello@grindy.io"
              >
                hello@grindy.io
              </a>

              <div
                className="d-flex"
                style={{
                  gap: "1rem",
                }}
              >
                <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.github}>
                  <img
                    alt="icon-github"
                    src={require("assets/images/icon/github.svg").default}
                  />
                </a>
                <a target="_blank" rel="noreferrer" href={SOCIAL_LINK.twitter}>
                  <img
                    alt="icon-twitter"
                    src={require("assets/images/icon/twitter.svg").default}
                  />
                </a>
              </div>
            </div>

            <div>
              <hr className="d-md-none divider-mx" />
              <div className="text-md-start">
                <h6 className="fs-5 text-white" style={{ fontWeight: 500 }}>
                  Grindy
                </h6>

                <ul
                  className="list-unstyled d-flex flex-column"
                  style={{
                    gap: "0.5rem",
                  }}
                >
                  {React.Children.toArray(
                    ListFooterItem.map((menu) => (
                      <li>
                        <Link
                          className="footer-media"
                          to={menu.link}
                          target={menu.target}
                          onClick={(e) => {
                            if (!menu.link) {
                              e.preventDefault();

                              const element = document.createElement("a");
                              element.href =
                                require("assets/grindy_logo.zip").default;
                              element.download = "grindy_logo";
                              element.click();
                            }
                          }}
                        >
                          {menu.context}
                        </Link>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <hr className="d-md-none divider-mx" />
            </div>

            <div>
              <h6 className="fs-6 text-white" style={{ fontWeight: 500 }}>
                Sign up to our Newletter
              </h6>
              <div className="input-group my-4 footer-signup">
                <input
                  type="text"
                  className="form-control fs-6 fw-normal"
                  placeholder="Email"
                />
                <span
                  className="btn-primary fs-6 d-flex align-items-center footer-padding"
                  style={{
                    cursor: "pointer",
                    fontWeight: 500,
                    borderBottomRightRadius: "0.5rem",
                    borderTopRightRadius: "0.5rem",
                  }}
                >
                  SUBCRIBE
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black">
          <div className="container-lg">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                padding: "1.8125rem 0",
              }}
            >
              <div
                className="fw-light"
                style={{
                  color: "#B5B5B5",
                  fontSize: "0.875rem",
                }}
              >
                2022 Grindy Technologies - All Rights Reserved
              </div>
              <div className="text-white fw-light fs-6">
                Terms & Private policy
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
