import React from "react";
import Container from "./components/Container";
import "./assets/style/font.css";
import "./assets/style/custom.scss";
import "./assets/style/utils.scss";

export default function App() {
  return (
    <div className="App d-flex flex-column">
      <Container />
    </div>
  );
}
