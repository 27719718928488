import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../constants";

import "./ContractUsForm.scss";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUsForm = ({ type }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [token, setToken] = useState(false);

  const recaptchaRef = React.useRef();

  const onSubmit = async (data) => {

    const link =
      "https://maker.ifttt.com/trigger/submit_contact/with/key/bn3DFwUQy0T6kAO5_OQRHH";

    try {
      await fetch(
        `${link}?value2=${data.email}&value1=${data.name}-${data.company}&value3=${data.message}`,
        {
          headers: {
            "Content-Type": "x-www-form-urlencoded",
          },
          method: "POST",
          mode: "no-cors",
        }
      );
      reset();
      setIsOpen(true);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };



  return (
    <>
      {type === "modal" && isOpen ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-field">
            <label htmlFor="name" className="contact-label">
              Name
            </label>

            <input
              placeholder="John Doe"
              type="text"
              {...register("name", {
                required: {
                  value: true,
                  message: "Require field",
                },
              })}
              className={`form-control ${errors.name && "is-invalid"} `}
              id="name"
            />
            {errors.name && (
              <div
                className="text-danger fw-light mt-1"
                style={{ fontSize: "0.75rem" }}
              >
                {errors.name?.message}
              </div>
            )}
          </div>

          <div className="mb-field">
            <label htmlFor="companyEmail" className="contact-label">
              Company Email
            </label>

            <input
              placeholder="john.doe@company.com"
              type="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Require field",
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid email address",
                },
              })}
              className={`form-control ${errors.email && "is-invalid"} `}
              id="companyEmail"
              aria-describedby="emailHelp"
            />
            {errors.email && (
              <div
                className="text-danger fw-light mt-1"
                style={{ fontSize: "0.75rem" }}
              >
                {errors.email?.message}
              </div>
            )}

            {!errors.email && (
              <div
                id="emailHelp"
                className="form-text pt-2 fw-light"
                style={{
                  fontSize: "0.75rem",
                }}
              >
                We'll never share your email with anyone else.
              </div>
            )}
          </div>

          <div className="mb-field">
            <label htmlFor="company" className="contact-label">
              Company
            </label>
            <input
              placeholder="Crypto Network"
              type="text"
              {...register("company", {
                required: {
                  value: true,
                  message: "Require field",
                },
              })}
              className={`form-control ${errors.company && "is-invalid"} `}
              id="company"
            />
            {errors.company && (
              <div
                className="text-danger fw-light mt-1"
                style={{ fontSize: "0.75rem" }}
              >
                {errors.company?.message}
              </div>
            )}
          </div>

          <div className="mb-form">
            <label htmlFor="message" className="contact-label">
              Message
            </label>

            <textarea
              placeholder="I want to discover more!"
              type="text"
              {...register("message", {
                required: {
                  value: true,
                  message: "Require field",
                },
              })}
              className={`form-control ${errors.message && "is-invalid"} `}
              id="message"
            />
            {errors.message && (
              <div
                className="text-danger fw-light mt-1"
                style={{
                  fontSize: "0.75rem",
                }}
              >
                {errors.message?.message}
              </div>
            )}
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // 6Lfm15ojAAAAACdcooJ7bkOdXthMtn5zit4jnqt3
            onChange={() => setToken(true)}
          />

          <div className="text-center">
            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitting | !token}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" className="mx-5" />
              ) : (
                "Send message"
              )}
            </Button>
          </div>
        </form>
      )}


      <Modal show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Thanks for contacting us!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>We'll get back to you as soon as we can.</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactUsForm;
