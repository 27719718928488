export default function SimpleLayout(props) {
  return (
    <main className="main">
      <div
        className="header-background mb-0"
        style={{ backgroundImage: `url(${props.bg})` }}
      >
        <div className="container">
          <div className="gap-6"></div>
          <div className="row mt-3 justify-content-center">
            <div className="col-lg-8 text-center">
              <h1 className="heading-g2">{props.title} </h1>

              {props.des && (
                <p
                  className="text-secondary text-body-2"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {props.des}
                </p>
              )}
            </div>
          </div>
          <div className="gap-6"></div>
        </div>
      </div>
      {props.children}
    </main>
  );
}
