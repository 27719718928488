import { BsArrowRight } from "react-icons/bs";

const ProductCard = (props) => {
  return (
    <div className="card card-product p-2">
      <div className="card-body">
        {props.img && (
          <div>
            <img
              src={require(`assets/images/products/${props.img}`).default}
              alt={props.title}
            />
          </div>
        )}
        <div style={{ paddingTop: "1.875rem" }}>
          <h3 className="fw-semibold">{props.title}</h3>

          <h5
            className="text-body-2 pb-0 pb-2"
            style={{ fontSize: "1.125rem", fontWeight: 500 }}
          >
            {props.subtitle}
          </h5>

          <div className="fs-6 fw-normal">{props.children}</div>
        </div>
      </div>

      <div className="card-footer">
        <a
          className="link-arrow fs-6"
          href={props.link}
          target="_blank"
          rel="noreferrer"
          style={{
            fontWeight: 500,
          }}
        >
          Explore now
          <BsArrowRight
            style={{ marginLeft: "0.3125rem" }}
            className="arrow-left ms-2"
          />
        </a>
      </div>
    </div>
  );
};

const Products = () => {
  return (
    <div className="d-flex products-container">
      <ProductCard
        title="Casper Stats"
        link="https://casperstats.io/"
        subtitle="Casper Blockchain Explorer"
        img="casper.svg"
      >
        <p>
          The Blockchain Explorer and Analytics Platform for Casper Network,
          Casper Network is a decentralized layer one Proof-of-Stake blockchain
          built off the Casper CBC specification. Casper is designed to
          accelerate enterprise and developer adoption of blockchain technology
          today and evolve to meet the needs of the future.
        </p>
        <a href="https://casperstats.io" target="_blank" rel="noreferrer">
          CasperStats.io
        </a>{" "}
        project supported by{" "}
        <a href="https://devxdao.com" target="_blank" rel="noreferrer">
          DEVxDAO.com
        </a>
      </ProductCard>

      <ProductCard
        title="Heroes & Empires"
        link="https://heroesempires.com/"
        subtitle="The next-gen RPG game"
        img="he.svg"
      >
        Heroes & Empires is a strategy game that combines the distinctive
        elements of Idle RPG and the tactics of auto chess, connected with
        blockchain technology. Besides, by taking advantage of the Non-Fungible
        Token (NFT) characteristic to create characters and items and
        Play-2-Earn elements, players can earn a steady income thanks to DeFi
        integration while still being satisfied with an entertaining gaming
        experience.
      </ProductCard>

      <ProductCard
        title="Gafi network"
        link="https://gafi.network/"
        subtitle="The Network of Game Finance"
        img="gafi-network.svg"
      >
        <p>
          The Network of Game Finance, The foundation builds with top-class
          technologies, architecture, and mechanisms for the next wave of
          Blockchain-based Gaming.
        </p>
        <a href="https://gafi.network" target="_blank" rel="noreferrer">
          Gafi Network
        </a>{" "}
        project supported by{" "}
        <a href="https://web3.foundation" target="_blank" rel="noreferrer">
          Web3.foundation
        </a>
      </ProductCard>

      <ProductCard
        title="Overmint Market"
        link="https://overmint.io/"
        subtitle="New NFT Market"
        img="overmint.svg"
      >
        The New NFT Market on EVM Based, Heroes & Empires has exquisitely
        digital collectibles created using blockchain technology. Each
        collectible is matchless, genuine and varies in rarity.
      </ProductCard>
    </div>
  );
};

export default Products;
