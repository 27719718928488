import Collaborate from "components/Collaborate/Collaborate";
import React from "react";

export default function CantFindSolution() {
  return (
    <div className="bg-light">
      <div className="bg-hero-3 solution-cant-find">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8">
              <h2 className="heading-g2 fw-bold fs-1">
                Can’t find solution for you?
              </h2>
              <p className="text-body-2" style={{ whiteSpace: "pre-line" }}>
                {`Everything begins with an idea, whether you’re an individual, a team, or a large
                enterprise. Let’s build a decentralized web together.`}
              </p>

              <Collaborate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
