import React from "react";

import SimpleLayout from "./layouts/SimpleLayout";
import { BsArrowRight } from "react-icons/bs";

import { FaLinkedin } from "react-icons/fa";

function Team(params) {
  return (
    <div className="col-6 col-lg-3 text-center mb-3">
      <div className="position-relative team-img-container">
        <a target="_blank" rel="noreferrer" href={params.href}>
          <FaLinkedin className="text-2 text-white team-linkedin" />
        </a>
        <img className="team-img" src={params.img} alt="team" />
      </div>
      <div className="gap-2"></div>
      <h5 className="text-2">{params.name}</h5>
      <p>{params.title}</p>
    </div>
  );
}
export default function About() {
  return (
    <SimpleLayout
      title="Our team"
      bg={require("assets/images/bg-tech.png").default}
      des={`From the money perspective, 
      Blockchain is the greatest gift in technologies for humanity.
      And, Money is freedom, isn't it?`}
    >
      <div className="">
        <div className="container">
          <div className="gap-3"></div>
          <div className="row justify-content-center">
            <div className="gap-3"></div>
            <Team
              img={require("assets/images/team/muto.png").default}
              name="Stefan Muto"
              title="Founder & CEO"
              href="https://www.linkedin.com/in/thanhbuiminh/"
            />
            <Team
              img={require("assets/images/team/mike.png").default}
              name="Mike Luu"
              title="Tech Lead"
              href="https://www.linkedin.com/in/trung-luu-hoang/"
            />

            <Team
              img={require("assets/images/team/alex.png").default}
              name="Alex Pham"
              title="UX/UI & Graphic"
              href="https://www.linkedin.com/in/alex-pham-01074b1b2/"
            />
            <Team
              img={require("assets/images/team/david.png").default}
              name="David Phan"
              title="Tech Lead H&E"
              href="https://www.linkedin.com/in/phan-dang-quy/"
            />
            <Team
              img={require("assets/images/team/jackson.png").default}
              name="Jackson Do"
              title="Blockchain Developer - DevOps"
              href="https://www.linkedin.com/in/trung-dt/"
            />
          </div>
        </div>
        <div className="gap-3"></div>
      </div>
      <div className="hr-line-rev"></div>
      <div className="bg-hero-2">
        <div className="gap-6"></div>

        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 d-none d-lg-block pe-5">
              <img
                src={require("assets/images/join-us.png").default}
                className=""
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <div className="gap-6"></div>
              <h5 className="text-3">Join our team</h5>
              {/* <p className="">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Euismod
                egestas sit sit mauris!
              </p> */}
              <button className="btn btn-lg btn-primary btn-arrow">
                Collaborate with us <BsArrowRight className="arrow" />
              </button>
              <div className="gap-6"></div>
            </div>
          </div>
        </div>
        <div className="gap-6"></div>
      </div>
    </SimpleLayout>
  );
}
